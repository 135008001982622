export default {
    navList: {
        nav1: 'Startseite',
        nav2: 'Vorteile',
        nav3: 'Portfolio',
        nav4: 'AI-Videos',
        nav5: 'Kontakt',
        login: 'Anmelden',
        register: 'Registrieren'
    },
    Language: "Sprache",
    down: 'Download',
    Menu: 'Menü',
    bannerTitle: '【STAR AI】Quant-Handel',
    bannerSubtitle: 'Stabile Rendite',
    bannerListTxt1: 'Rebalancing',
    bannerListTxt2: 'Portfolio-Management',
    bannerListTxt3: 'Datensammlung',
    bannerListTxt4: 'Intelligente Order-Routing',
    bannerDownBtn: 'Android-Version laden',
    bannerSubscribeBtn: 'Jetzt abonnieren',
    ProductAdvantages: 'Vorteile und Unterschiede',
    advantagesList: {
        title1: 'Der Unterschied zwischen Produktvorteilen und Arbeitsaufwand',
        describe1: "Die Designvorteile quantitativer KI-Strategiemodelle bestehen aus mehr als Hunderten von Faktoren, die den Anstieg und Rückgang von Beständen beeinflussen können. Es verfügt über unabhängige Deep-Learning-Fähigkeiten und kann mit dem Hauptgeldfluss Schritt halten. Für manuelle Analysen und Recherchen ist es schwierig, solche Anforderungen zu erfüllen.",
        title2: 'Vorteile der Testfunktion für die Rückgabe historischer Daten',
        describe2: 'Um ein quantitatives KI-Modell mit einer Rendite zu entwerfen, die weit über der Marktrendite liegt, können Sie die historische jährliche Rendite des Modells durch Backtesting historischer Zyklusdaten ermitteln. Daraus kann das idealste Modell ausgewählt werden. Dies ist manuell einfach unmöglich zu bewerkstelligen, egal wie viel Zeit es kostet. Daher sind manuelle Operationen oft nicht streng und unwissenschaftlich und verlassen sich bei der Auswahl der Operationsziele nur auf persönliche Gefühle und Erfahrungen.',
        title3: 'Vorteile des automatischen Scannens von mehr als 400.000 Aktien auf dem Weltmarkt',
        describe3: 'Der KI-Roboter scannt im Laufe des Tages automatisch mehr als 400.000 Aktien im gesamten Markt gemäß den von quantitativen Modelltechnikern festgelegten Bedingungen und anderen festgelegten Parametern und schließt den Vorgang sofort ab, wenn er feststellt, dass die festgelegten Zielbedingungen erfüllt sind. Es dauert nur ein paar Sekunden. Eine manuelle Analyse würde Jahre oder sogar Jahrzehnte dauern und wäre nicht in der Lage, den gleichen Arbeitsaufwand zu bewältigen.',
        title4: 'Nur wenn wir die Geschichte verstehen, können wir die Zukunft genauer beurteilen',
        describe4: 'Die Backtest-Renditerate der historischen Daten des quantitativen Modells kann als bessere Referenz für die Beurteilung zukünftiger Renditeraten verwendet werden. Technische Ingenieure müssen lediglich Parameter in Richtung der an diesem Tag gekauften Aktie direkt eingeben und können die tägliche Renditerate testen der Aktie zum Kaufzeitpunkt. Um diese Anforderungen zu erfüllen, ist eine manuelle Bedienung schlichtweg unmöglich.',
        title5: 'Etablieren Sie ein langfristiges und stabiles Gewinnmodell',
        describe5: 'Um langfristig stabile Gewinne und enorme Zinseszinsen zu erzielen, können technische Ingenieure ihre eigenen ausgewählten Indikatorparameter anpassen und hinzufügen, die Rendite durch Backtesting überprüfen, die idealste Strategie oder Kombination auswählen und dann den vollautomatischen Handel mit KI-Robotern starten. Diese Art von Arbeit kann nicht manuell durchgeführt werden.',
        title6: 'Operative Ausführungsfähigkeit',
        describe6: 'STAR Der Vorteil des vollautomatischen quantitativen Handels von STAR AI besteht darin, dass er rationale Werkzeuge verwendet, um Transaktionen gemäß den Modelleinstellungen durchzuführen. Der Roboter unterliegt keinen emotionalen Veränderungen und kann die menschliche Natur, menschliche Gier und Angst sowie viele andere Mängel und Schwächen im Betriebsprozess überwinden. Manuelle Vorgänge werden oft durch den Anstieg und Fall des Marktes und verschiedene andere Faktoren beeinflusst und können nicht über einen längeren Zeitraum nach dem besten Gewinnmodell betrieben werden.',
        title7: 'Geben Sie Ihrer Produktivität völlig freien Lauf',
        describe7: 'Der quantitative STAR AI-Roboter kann einmal eingestellt werden und führt über einen längeren Zeitraum automatisch Operationen gemäß den Einstellungen durch. Er muss den Kapitalzuteilungsprozess nur einmal am Tag durchführen, wodurch Benutzer vollständig von der mühsamen Arbeit des Aktienhandels befreit werden können. Manuelle Analysen und Recherchen, Marktverfolgung und manuell anvertrauter Handel erfordern jedoch viel Zeit und Energie von den Anlegern.',
    },
    investmentPackage: "Wählen Sie Ihr Portfolio",
    versionTab1: 'Basis',
    versionTab2: 'Einsteiger',
    versionTab3: 'Standard',
    versionTab4: 'Fortgeschritten',
    versionTab5: 'Profi',
    versionTab6: 'Unternehmen',
    versionTab7: 'Individuell',
    BusinessDocking: 'Geschäftskontakt',
    email: "E-Mail",
    officialEmail: 'Offizielle E-Mail',
    privacyPolicy: 'Datenschutz',
    TermOfService: "Nutzungsbedingungen",
    Copyright: 'Urheberrecht'
}