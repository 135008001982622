<template>
  <div class="header">
    <div class="header_container">
      <div class="logo_wrap">
        <img src="@/assets/images/logo.png" alt="">
      </div>


      <div class="mb_lang_menu_wrap">
        <!-- <div @click="mobileLanguageShow = true">{{ languageData.find(item => item.Value === nowLanguageData)?.label }}
        </div> -->

        <button class="navbar-toggle c-navmenubutton" @click="mobileMenuShow = true">
          <span class="icon-bar c-nav-btn-span "></span>
          <span class="icon-bar c-nav-btn-span "></span>
          <span class="icon-bar c-nav-btn-span "></span>
        </button>

      </div>




      <div class="nav_wrap">
        <ul class="nav_list">
          <li class="nav_item" v-for="(item, index) in navList" :key="index" @click="toPage(item, index)">
            {{ $t(item.name) }}
          </li>

        </ul>
        <div>
          <a href="https://starai.store/login/index" class="">{{ $t('navList.login') }}</a>
          /
          <a href="https://starai.store/login/reg" class="">{{ $t('navList.register') }}</a>
        </div>
        <div class="header_btn_wrap">
          <button type="button" class="down_btn">{{ $t('down') }}</button>
          <!-- <div class="language-btn">
            <div class="language-text flex items-center ">
              <span>{{ languageData.find(item => item.Value === nowLanguageData)?.label }}</span>
              <div class=" relative before:absolute ml-1 flex items-center before:content-['\e904']">
              </div>
            </div>
            <ul class=" language-list">
              <li v-for="(item, index) in languageData" :key="index" @click="changeLanguage(item.Value)"
                class="py-2 text-white hover:text-default-color">{{ item.label }}</li>
            </ul>
          </div> -->
        </div>
      </div>

    </div>

    <transition name="slide-menu-left">
      <div v-show="mobileLanguageShow" class="mobile-menu-wrapper mobile-menu">
        <p class="menu-header">
          {{ $t('Language') }}
        </p>
        <div @click="mobileLanguageShow = false" class="menu-close-button">
          <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.9001 10.96C11.9632 11.0226 11.9987 11.1078 11.9987 11.1967C11.9987 11.2855 11.9632 11.3707 11.9001 11.4333L11.4334 11.9C11.3708 11.9631 11.2856 11.9986 11.1967 11.9986C11.1079 11.9986 11.0227 11.9631 10.9601 11.9L8.00006 8.94L5.04006 11.9C4.97748 11.9631 4.89228 11.9986 4.8034 11.9986C4.71452 11.9986 4.62932 11.9631 4.56673 11.9L4.10006 11.4333C4.03696 11.3707 4.00146 11.2855 4.00146 11.1967C4.00146 11.1078 4.03696 11.0226 4.10006 10.96L7.06006 8L4.10006 5.04C4.03696 4.97741 4.00146 4.89222 4.00146 4.80334C4.00146 4.71446 4.03696 4.62926 4.10006 4.56667L4.56673 4.1C4.62932 4.0369 4.71452 4.0014 4.8034 4.0014C4.89228 4.0014 4.97748 4.0369 5.04006 4.1L8.00006 7.06L10.9601 4.1C11.0227 4.0369 11.1079 4.0014 11.1967 4.0014C11.2856 4.0014 11.3708 4.0369 11.4334 4.1L11.9001 4.56667C11.9632 4.62926 11.9987 4.71446 11.9987 4.80334C11.9987 4.89222 11.9632 4.97741 11.9001 5.04L8.94006 8L11.9001 10.96Z"
              fill="black" />
          </svg>
        </div>
        <ul class="language-list">
          <li v-for="(item, index) in languageData" :key="index" @click="changeLanguage(item.Value)"
            class="language-item">
            <i class="language-icon"></i>
            <span>{{ item.label }}</span>
          </li>
        </ul>
      </div>
    </transition>

    <transition name="slide-menu-left">
      <div v-if="mobileMenuShow" class="mobile-menu-wrapper">
        <p class="menu-header">
          {{ $t('Menu') }}
        </p>
        <div @click="mobileMenuShow = false" class="menu-close-button">
          <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.9001 10.96C11.9632 11.0226 11.9987 11.1078 11.9987 11.1967C11.9987 11.2855 11.9632 11.3707 11.9001 11.4333L11.4334 11.9C11.3708 11.9631 11.2856 11.9986 11.1967 11.9986C11.1079 11.9986 11.0227 11.9631 10.9601 11.9L8.00006 8.94L5.04006 11.9C4.97748 11.9631 4.89228 11.9986 4.8034 11.9986C4.71452 11.9986 4.62932 11.9631 4.56673 11.9L4.10006 11.4333C4.03696 11.3707 4.00146 11.2855 4.00146 11.1967C4.00146 11.1078 4.03696 11.0226 4.10006 10.96L7.06006 8L4.10006 5.04C4.03696 4.97741 4.00146 4.89222 4.00146 4.80334C4.00146 4.71446 4.03696 4.62926 4.10006 4.56667L4.56673 4.1C4.62932 4.0369 4.71452 4.0014 4.8034 4.0014C4.89228 4.0014 4.97748 4.0369 5.04006 4.1L8.00006 7.06L10.9601 4.1C11.0227 4.0369 11.1079 4.0014 11.1967 4.0014C11.2856 4.0014 11.3708 4.0369 11.4334 4.1L11.9001 4.56667C11.9632 4.62926 11.9987 4.71446 11.9987 4.80334C11.9987 4.89222 11.9632 4.97741 11.9001 5.04L8.94006 8L11.9001 10.96Z"
              fill="black" />
          </svg>
        </div>
        <div class="menu-actions">
          <a href="https://starai.store/login/index" class="login-button">
            {{ $t('navList.login') }}
          </a>
          <a href="https://starai.store/login/reg" class="signup-button">
            {{ $t('navList.register') }}
          </a>
        </div>
        <ul class="menu-list">
          <li v-for="(item, index) in navList" :key="index" @click="toPage(item, index)" class="menu-item">
            <div class="menu-link">
              <i class="menu-icon"></i>
              <span>{{ $t(item.name) }}</span>
            </div>
          </li>
        </ul>
      </div>
    </transition>

  </div>
</template>

<script setup>
import { defineProps, defineEmits, inject, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n()

// 注入 reload
const reload = inject('reload');

// 定义 props
const emit = defineEmits(['scrollTo']);

const mobileLanguageShow = ref(false)
const mobileMenuShow = ref(false)
// const nowLanguageData = ref(localStorage.getItem('lang') || 'es')
const nowLanguageData = ref('es')
const languageData = [
// {
//     label: "中文",
//     Value: "zh"
//   },
  {
    label: "English",
    Value: "en"
  },
  {
    label: "Deutsch",
    Value: "de"
  },
  {
    label: "Français",
    Value: "fr"
  },
  {
    label: "Español",
    Value: "es"
  },
  {
    label: "Italiano",
    Value: "ita"
  },
  {
    label: "Português",
    Value: "pt"
  },
  {
    label: "Nederlands",
    Value: "nl"
  },
  {
    label: "Ελληνικά",
    Value: "el"
  },
  {
    label: "Język polski",
    Value: "pl"
  },
]
const navList = [
  { name: "navList.nav1", id: "bannerWrap", to: "/" },
  { name: "navList.nav2", id: "advantage", to: "/" },
  { name: "navList.nav3", id: "combination", to: "/" },
  { name: "navList.nav4", id: "explain", to: "/" },
  { name: "navList.nav5", id: "contact", to: "/" }
]

const router = useRouter();
const route = useRoute();
const toPage = (item, index) => {

  emit('scrollTo', item.id);
  mobileMenuShow.value = false
  router.push({
    path: item.to,
    query: {
      id: item.id,
    },

  });
  // this.$emit("scrollTo", item.id)
  // this.mobileMenuShow = false
  // this.$router.push({
  //     path: item.to,
  //     query: {
  //         id: item.id
  //     }
  // })
}

const changeLanguage = (key) => {
  mobileLanguageShow.value = false
  nowLanguageData.value = key
  locale.value = key
  localStorage.setItem('lang', key)
  router.go(0)  
}
</script>

<style scoped lang="less">
.header {
  position: fixed;
  width: 100%;

  background: #fff;
  z-index: 999;

}

.header_container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 70px;

  @media (max-width: 900px) {
    min-height: 50px;
    padding: 0 15px;
  }
}

.logo_wrap {
  width: 145px;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    width: 125px;
  }
}

.logo_wrap>img {
  max-width: 100%;
  max-height: 70px;
  width: auto;
  height: auto;
  object-fit: cover;

  @media (max-width: 900px) {
    max-height: 50px;
  }
}

.nav_wrap {
  display: flex;
  width: fit-content;
  height: 100%;
  align-items: center;

  @media (max-width: 900px) {
    display: none;
  }
}

.nav_list {
  display: flex;
  align-items: center;
  list-style: none;
  margin-bottom: 0;
}

.nav_item {
  position: relative;
  padding: 15px;
  color: #434343;
  font-size: 14px;
  cursor: pointer;
}

.nav_item::after {
  content: "";
  display: block;
  width: 0;
  height: 3px;
  background-color: #1d1d1d;
  position: absolute;
  opacity: 1;
  bottom: 0;
  left: 15px;
  right: auto;
  top: auto;
  transition: all 0s ease;
}

.nav_item:hover::after {
  content: "";
  display: block;
  width: calc(100% - 30px);
  transition: all 0.3s ease;
}

.down_btn {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
  min-width: 100px;
  border-radius: 40px;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
}

.header_btn_wrap {
  margin-left: 12px;
  display: flex;
  align-items: stretch;
  gap: 14px;
}

.navbar-toggle {
  position: relative;
  padding: 9px 10px;
  margin-top: 8px;
  margin-right: 2px;
  margin-bottom: 8px;
  background-color: rgba(0, 0, 0, 0.05);
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;

}

.icon-bar {
  background-color: #434343 !important;
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}

.icon-bar+.icon-bar {
  margin-top: 4px;
}


.mobile-menu {
  display: block;

  @media (min-width: 900px) {
    display: none;
  }

  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: white;
  top: 0;
  left: 0;
  z-index: 20;


  .menu-close {
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    top: 0.25rem;
    right: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .language-list {
    color: black;
    font-family: 'icomoon';

    .language-item {
      display: flex;
      align-items: center;
      font-size: 1rem;
      padding-right: 1rem;
      height: 3rem;
      line-height: 3rem;
      border-bottom: 1px solid #38445529;

      .language-icon {
        display: block;
        width: 6px;
        height: 6px;
        background-color: #384455 / 50%;
        border-radius: 50%;
        margin-left: 24px;
        margin-right: 12px;
      }
    }
  }
}


.slide-menu-left-enter-active,
.slide-menu-left-leave-active {
  transition: transform 0.3s ease-out;
}

.slide-menu-left-enter-from,
.slide-menu-left-leave-to {
  transform: translateX(-100%);
}

.slide-menu-left-enter-to,
.slide-menu-left-leave-from {
  transform: translateX(0);
}


.button-bg {
  background: linear-gradient(135deg, #00e8c7, #00cc96)
}

.has-child {
  &:hover {

    .child-menu,
    .home-nav_feature {
      opacity: 1;
      pointer-events: unset;
    }
  }

  .child-menu {
    pointer-events: none;
    list-style: none;
    padding: 8px 16px;
    margin: 0;
    opacity: 0;
    top: 100%;
    transition: opacity .5s;
    left: -165px;
    background-color: #212325;
    border: 1px solid #353535;
    box-shadow: 0 0 16px rgba(0, 0, 0, .5);
  }

  .home-nav_feature {
    pointer-events: none;
    transform: rotate(45deg);
    position: absolute;
    width: 16px;
    height: 16px;
    opacity: 0;
    left: calc(50% - 9.5px);
    top: calc(100% - 7px);
    background-color: #212325;
    // transition: opacity .5s;
    clip-path: polygon(0 0, 0 100%, 100% 0);
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 0);
    -moz-clip-path: polygon(0 0, 0 100%, 100% 0);
    border-color: #353535 transparent transparent #353535;
    border-style: solid;
    border-width: 1px;
  }
}

.menu-header {
  display: flex;
  align-items: center;
  color: rgb(10, 26, 49, .5);
  padding-left: 24px;
  height: 48px;
  border-bottom: 1px solid #38445529;

  h3 {
    margin: 0 !important;
    font-size: 18px;
  }
}


.mobile-menu-wrapper {
  display: block;

  @media (min-width: 900px) {
    display: none;
  }

  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: white;
  top: 0;
  left: 0;
  z-index: 20;



  .menu-close-button {
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    top: 0.25rem;
    right: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-actions {
    padding: 16px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    .login-button,
    .signup-button {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3rem;
      border-radius: 4px;
    }

    .login-button {
      border: 1px solid #00cc96;
      color: #00cc96;
    }

    .signup-button {
      background: linear-gradient(135deg, #00e8c7, #00cc96);
      color: white;
    }
  }

  .menu-list {
    padding: 0 24px;
    color: black;
    font-family: 'icomoon';

    .menu-item {
      font-size: 1rem;
      padding-right: 1rem;
      min-height: 3rem;
      line-height: 3rem;

      .menu-link {
        display: flex;
        align-items: center;

        .menu-icon {
          width: 6px;
          height: 6px;
          background-color: #384455 / 50%;
          border-radius: 50%;
          margin-left: 24px;
          margin-right: 12px;
        }
      }

      .submenu-list {
        .submenu-item {
          font-size: 1rem;
          padding-right: 1rem;
          height: 3rem;
          line-height: 3rem;

          .submenu-link {
            padding-left: 56px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}


.language-btn {
  position: relative;
  min-height: 100%;
  padding-left: 6px;
  padding-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .language-list {
    position: absolute;
    z-index: 10;
    display: none;
    width: 144px;
    padding: 16px 20px;
    right: 0;
    top: 32px;
    border: 1px solid rgba(255, 255, 255, 0.16);
    background-color: rgba(33, 35, 37, 0.8);
  }

  &:hover {
    .language-list {
      display: block;

      li {
        list-style: none;
        color: #fff;
        padding: 8px 0;

        &:hover {
          color: #d139f9;
        }
      }
    }

    .language-text {
      div {
        &::before {
          transform-origin: center;
          transform: rotate(180deg);
          transition: all .5s;
        }
      }
    }
  }
}


.mb_lang_menu_wrap {
  align-items: center;
  gap: 12px;
  display: none;

@media (max-width: 900px) {
  display: flex;
}
}
</style>